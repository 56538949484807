import React, { useMemo } from 'react';
import { Button, SIZES, VARIANTS, Loading } from '@bornlogic/gd-ui';

import { CentralBox, Image, LoginTitle } from 'components/atoms';
import { useAuth } from 'services/contexts/Auth';
import BornlogicLogo from 'utils/assets/bornlogic_logo.png';
import BornlogicIcon from 'utils/assets/bornlogic_icon.png';

function Auth() {
  const { isAuthChecking, login } = useAuth();

  const renderContent = useMemo(() => {
    if (isAuthChecking) {
      return <Loading variant={VARIANTS.secondary} />;
    }

    return (
      <>
        <LoginTitle />
        <Button
          icon={<Image src={BornlogicIcon} alt="Bornlogic Icon" customStyle="h-7" />}
          label="Conectar-se com Bornlogic"
          variant={VARIANTS.black}
          size={SIZES.large}
          onClick={login}
          dataTestid="testAuthPage"
        />
      </>
    );
  }, [isAuthChecking]);

  return (
    <div className="h-screen pt-5 pl-6">
      <Image src={BornlogicLogo} alt="Bornlogic Logo" />
      <CentralBox customStyle="mt-20">{renderContent}</CentralBox>
    </div>
  );
}

export default Auth;
