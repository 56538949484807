import React, { ReactNode } from 'react';

interface CentralBoxProps {
  children: ReactNode;
  customStyle?: string;
}

function CentralBox({ children, customStyle = '' }: CentralBoxProps) {
  return (
    <div className={`flex justify-center flex-col items-center ${customStyle}`}>{children}</div>
  );
}

export default CentralBox;
