import React from 'react';

import { Image } from 'components/atoms';
import BornlogicLogo from 'utils/assets/bornlogic_logo.png';
import { useNavigate } from 'react-router-dom';
import { Button, SIZES, VARIANTS } from '@bornlogic/gd-ui';
import { useAuth } from 'services/contexts/Auth';
import { ExitIcon } from 'components/icons';

function PageHeader() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  return (
    <div className="w-full bg-primaryWhite py-6 px-8 h-24 flex justify-between items-center">
      <Image src={BornlogicLogo} alt="Bornlogic Logo" onClick={() => navigate('/')} />
      <div className="xl:hidden">
        <Button
          label="Disconnect"
          onClick={logout}
          size={SIZES.standard}
          variant={VARIANTS.tertiary}
          icon={<ExitIcon className="stroke-securityBlue-primary h-7 w-7" />}
        />
      </div>
    </div>
  );
}

export default PageHeader;
