import React from 'react';

import { AuthProvider } from 'services/contexts/Auth';
import { SurveyFormProvider } from 'services/contexts/SurveyForm';
import { SurveyProvider } from 'services/contexts/Survey';
import Routes from 'services/routes';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <SurveyProvider>
          <SurveyFormProvider>
            <Routes />
          </SurveyFormProvider>
        </SurveyProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
