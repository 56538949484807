import React from 'react';

import { NavMenu, SidebarMenu } from 'components/atoms';

function Sidebar() {
  return (
    <nav className="w-60 bg-primaryWhite flex flex-col justify-between maxXl:hidden">
      <NavMenu />
      <SidebarMenu />
    </nav>
  );
}

export default Sidebar;
