import React, { ChangeEvent, useMemo, useState } from 'react';

import { Card, Loading, SIZES, VARIANTS } from '@bornlogic/gd-ui';
import { CardHeader } from 'components/molecules';
import { CentralBox, TextInput } from 'components/atoms';
import Table from 'components/molecules/Table';
import { useNavigate } from 'react-router-dom';
import { useSurvey } from 'services/contexts/Survey';

function SurveysList() {
  const [surveyFilter, setSurveyFilter] = useState<string>('');
  const { surveyList, isLoading } = useSurvey();
  const navigate = useNavigate();

  const filteredSurveyList = useMemo(() => {
    if (!surveyFilter) {
      return surveyList;
    }

    return surveyList.filter(
      (survey) => survey.id.includes(surveyFilter) || survey.name.includes(surveyFilter)
    );
  }, [surveyList, surveyFilter]);

  return (
    <Card>
      {isLoading ? (
        <CentralBox customStyle="w-full h-full">
          <Loading variant={VARIANTS.black} size={SIZES.large} />
        </CentralBox>
      ) : (
        <>
          <CardHeader
            title="Surveys"
            rightButtonLabel="Add Survey"
            onClickRightButton={() => navigate('/survey')}
          />
          <TextInput
            placeholder="Find by Survey's name or ID"
            onSearch={(event: ChangeEvent<HTMLInputElement>) => setSurveyFilter(event.target.value)}
            value={surveyFilter}
          />

          <Table bodyData={filteredSurveyList} />
        </>
      )}
    </Card>
  );
}

export default SurveysList;
