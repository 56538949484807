import React from 'react';
import { IconProps } from '..';

function TrashCan({ className }: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className={className}>
      <path d="M13.2 2.7999L11.94 1.1199C11.5987 0.666152 11.0662 0.399902 10.5 0.399902H7.49998C6.93373 0.399902 6.40123 0.666152 6.05998 1.1199L4.79998 2.7999H1.49998C1.00123 2.7999 0.599976 3.20115 0.599976 3.6999V3.7749C0.599976 3.89865 0.701226 3.9999 0.824976 3.9999H1.79998V17.7999C1.79998 18.7937 2.60623 19.5999 3.59998 19.5999H14.4C15.3937 19.5999 16.2 18.7937 16.2 17.7999V3.9999H17.175C17.2987 3.9999 17.4 3.89865 17.4 3.7749V3.6999C17.4 3.20115 16.9987 2.7999 16.5 2.7999H13.2ZM7.49998 1.5999H10.5C10.6875 1.5999 10.8675 1.6899 10.98 1.8399L11.7 2.7999H6.29998L7.01998 1.8399C7.13248 1.6899 7.31248 1.5999 7.49998 1.5999ZM15 17.7999C15 18.1299 14.73 18.3999 14.4 18.3999H3.59998C3.26998 18.3999 2.99998 18.1299 2.99998 17.7999V3.9999H15V17.7999ZM8.39998 16.1499V6.2499C8.39998 6.0024 8.60248 5.7999 8.84998 5.7999H9.14998C9.39748 5.7999 9.59998 6.0024 9.59998 6.2499V16.1499C9.59998 16.3974 9.39748 16.5999 9.14998 16.5999H8.84998C8.60248 16.5999 8.39998 16.3974 8.39998 16.1499ZM5.39998 16.1499V6.2499C5.39998 6.0024 5.60248 5.7999 5.84998 5.7999H6.14998C6.39748 5.7999 6.59998 6.0024 6.59998 6.2499V16.1499C6.59998 16.3974 6.39748 16.5999 6.14998 16.5999H5.84998C5.60248 16.5999 5.39998 16.3974 5.39998 16.1499ZM11.4 16.1499V6.2499C11.4 6.0024 11.6025 5.7999 11.85 5.7999H12.15C12.3975 5.7999 12.6 6.0024 12.6 6.2499V16.1499C12.6 16.3974 12.3975 16.5999 12.15 16.5999H11.85C11.6025 16.5999 11.4 16.3974 11.4 16.1499Z" />
    </svg>
  );
}

export default TrashCan;
