import React, { useEffect, useMemo } from 'react';

import { Question, Answers } from 'components/organisms';
import { Question as QuestionType } from 'utils/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSurveyForm } from 'services/contexts/SurveyForm';

export interface SelectedQuestion {
  selectedQuestion: QuestionType;
}

function CreateQuestionAndAnswers() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [id] = searchParams.getAll('id');
  const { questions } = useSurveyForm();

  const selectedQuestion = useMemo(() => {
    return questions.filter((question) => question.id === id)[0] || {};
  }, [questions]);

  useEffect(() => {
    if (!selectedQuestion.id) {
      navigate(-1);
    }
  }, [id, selectedQuestion.id]);

  return (
    <div className={`flex w-full gap-6 ${selectedQuestion.type === 'TEXT' && 'w-[50%]'}`}>
      <Question selectedQuestion={selectedQuestion} />
      {!!selectedQuestion.options_type && selectedQuestion.type !== 'TEXT' && (
        <Answers selectedQuestion={selectedQuestion} />
      )}
    </div>
  );
}

export default CreateQuestionAndAnswers;
