import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';

import {
  mountUrlToLogin,
  mountUrlToLogout,
  getCallbackParams,
  isValidState,
  getParamsForToken,
  getTokens,
  hasTokenExpired,
  getParamsToRefreshToken,
  setNewToken,
  setUserTokenAndBusinessId,
} from 'utils/helpers/oAuth';
import jwt_decode from 'jwt-decode';
import { authenticateApi, checkSubDomain } from 'services/oAuth';
import { AUTH_TOKENS } from 'utils/helpers/consts';
import { User } from 'utils/types';
import { toast } from 'react-toastify';

interface AuthContextData {
  login(): void;
  logout(): void;
  hasAuthToken(): boolean;
  isAuthChecking: boolean;
  user: User;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function AuthProvider({ children }: { children: JSX.Element }) {
  const [isAuthChecking, setAuthChecking] = useState<boolean>(false);
  const [user, setUser] = useState<User>(
    localStorage[AUTH_TOKENS.USER_TOKEN]
      ? jwt_decode(localStorage[AUTH_TOKENS.USER_TOKEN])
      : ({} as User)
  );

  function hasAuthToken() {
    const { authServerToken } = getTokens();
    return !!authServerToken;
  }

  async function handleToken(params: URLSearchParams) {
    try {
      await setNewToken(params);
      const { data: bornlogicData } = await checkSubDomain('1.2', 'bornlogic');
      const { data: userData } = await authenticateApi(bornlogicData.id);
      setUserTokenAndBusinessId({ businessId: bornlogicData.id, userToken: userData.token });
      const decoded: User = jwt_decode(userData.token);
      setUser(decoded);
      setAuthChecking(false);
    } catch (error) {
      mountUrlToLogout();
      toast.error('Error in authentication');
    }
  }

  function initProvider() {
    setAuthChecking(true);
    const { code, state } = getCallbackParams();

    if (!hasAuthToken() && isValidState(state)) {
      const tokenParams = getParamsForToken(code);
      return handleToken(tokenParams);
    }

    const { expirationTokenTime } = getTokens();

    if (hasAuthToken() && hasTokenExpired(expirationTokenTime)) {
      const tokenParams = getParamsToRefreshToken(code);
      return handleToken(tokenParams);
    }

    return setAuthChecking(false);
  }

  useEffect(() => {
    initProvider();
  }, []);

  const contextValue = useMemo(
    () => ({
      login: () => mountUrlToLogin(),
      logout: () => mountUrlToLogout(),
      hasAuthToken,
      isAuthChecking,
      user,
    }),
    [isAuthChecking]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return useContext(AuthContext);
}

export { AuthContext, AuthProvider, useAuth };
