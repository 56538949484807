import React from 'react';
import { IconProps } from '..';

function Enter({ className }: IconProps) {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M4.8031 1.57655L4.64779 1.73186C4.54498 1.83468 4.54498 2.00093 4.64779 2.10374L7.18529 4.62811H0.362476C0.218101 4.62811 0.0999756 4.74624 0.0999756 4.89061V5.10936C0.0999756 5.25374 0.218101 5.37186 0.362476 5.37186H7.18529L4.64998 7.89624C4.54716 7.99905 4.54716 8.1653 4.64998 8.26811L4.80529 8.42343C4.9081 8.52624 5.07435 8.52624 5.17716 8.42343L8.4256 5.18593C8.52841 5.08311 8.52841 4.91686 8.4256 4.81405L5.17498 1.57655C5.07216 1.47374 4.90591 1.47374 4.8031 1.57655ZM9.89998 1.06249V8.93749C9.89998 9.08186 9.78185 9.19999 9.63748 9.19999H9.46248C9.3181 9.19999 9.19998 9.08186 9.19998 8.93749V1.06249C9.19998 0.918113 9.3181 0.799988 9.46248 0.799988H9.63748C9.78185 0.799988 9.89998 0.918113 9.89998 1.06249Z" />
    </svg>
  );
}

export default Enter;
