import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { HouseIcon, MenuIcon } from 'components/icons';

const activeStyle = 'bg-seriousBlack-lighter0 border-l-4 border-seriousBlack-darker';

function SidebarMenu() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const actualPage = useMemo(() => pathname.split('/'), [pathname]);
  const isActive = useCallback((item: string) => actualPage.includes(item), [actualPage]);

  return (
    <ul className="w-full">
      <li>
        <div
          className={`flex flex-row items-center p-3 ${isActive('list') && activeStyle}`}
          role="button"
          onClick={() => navigate('/list')}
          onKeyDown={() => navigate('/list')}
          tabIndex={0}
        >
          <HouseIcon className="w-6 h-6 mr-3" />
          Surveys
        </div>
      </li>
      <li>
        <div
          className={`flex flex-row items-center p-3 ${isActive('survey') && activeStyle}`}
          role="button"
          onClick={() => navigate('/survey')}
          onKeyDown={() => navigate('/survey')}
          tabIndex={0}
        >
          <MenuIcon className="w-7 h-7 mr-3" />
          New
        </div>
      </li>
    </ul>
  );
}

export default SidebarMenu;
