import React from 'react';

import { Card } from '@bornlogic/gd-ui';
import { CardHeader } from 'components/molecules';
import { useNavigate } from 'react-router-dom';
import { useSurveyForm } from 'services/contexts/SurveyForm';
import { InfoBox } from 'components/atoms';
import { EnterIcon } from 'components/icons';

function Audiences() {
  const navigate = useNavigate();
  const { audiences, setAudiences, infoErrors } = useSurveyForm();

  function setCreateAudience() {
    const nextId = Number(audiences.at(-1)?.id) + 1 || 1;

    setAudiences((state) => [...state, { data: [], id: String(nextId), type: '' }]);

    navigate(`/survey/audience?id=${nextId}`);
  }

  return (
    <Card>
      <CardHeader
        title="Audiences"
        hasDivider
        onClickRightButton={() => setCreateAudience()}
        rightButtonLabel="Add Audience"
      />
      <div className="w-full overflow-y-auto h-[calc(100vh-21rem)]">
        {audiences?.length > 0
          ? audiences.map(
              (audience) =>
                !!audience.data?.length && (
                  <InfoBox key={audience.id} className="flex items-center justify-between p-8">
                    <div className="flex flex-col justify-between gap-8">
                      <h3 className="font-bold text-xl">{audience.type}</h3>

                      <div className="flex flex-col">
                        {audience.data.map((value) => (
                          <span key={`${audience.id}`}>{value}</span>
                        ))}
                      </div>
                    </div>
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => navigate(`/survey/audience?id=${audience.id}`)}
                      onKeyDown={() => navigate(`/survey/audience?id=${audience.id}`)}
                      className="w-8 h-8"
                    >
                      <EnterIcon className="stroke-seriousBlack-primary" />
                    </div>
                  </InfoBox>
                )
            )
          : !!infoErrors.allowed_tenants && (
              <span className="text-warningRed-primary">{infoErrors.allowed_tenants}</span>
            )}
      </div>
    </Card>
  );
}

export default Audiences;
