/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { GetStatusLabelParams, Survey } from 'utils/types';
import { Switch } from '@bornlogic/gd-ui';
import { useNavigate } from 'react-router-dom';
import { useSurveyForm } from 'services/contexts/SurveyForm';
import { format } from 'date-fns';
import { AiFillWindows, AiFillApple, AiFillAndroid, AiFillEdit } from 'react-icons/ai';

interface TableProps {
  bodyData: Survey[];
}

const headerData = ['Name', 'Status', 'Platform', 'Period', ''];

function Table({ bodyData }: TableProps) {
  const { handleUpdateSurveyStatus } = useSurveyForm();
  const navigate = useNavigate();

  function getStatusLabel({ status, id }: GetStatusLabelParams) {
    if (status === 'DRAFT') {
      return (
        <button
          type="button"
          className="btn btn-link p-0 text-securityBlue-primary"
          onClick={() => handleUpdateSurveyStatus({ surveyId: id, status: true })}
        >
          Set Active (Draft)
        </button>
      );
    }
    return (
      <>
        <Switch
          checked={status === 'ACTIVE'}
          onToggle={(checked) => handleUpdateSurveyStatus({ surveyId: id, status: checked })}
          id={id}
        />
        <span>{status}</span>
      </>
    );
  }

  function getTargetIcons(targets: string[]) {
    if (!targets?.length) {
      return 'Uninformed';
    }

    return (
      <div className="flex items-center">
        {targets?.includes('WEB') && (
          <AiFillWindows className="stroke-seriousBlack-primary text-xl" />
        )}
        {targets?.includes('APP_ANDROID') && (
          <AiFillAndroid className="stroke-seriousBlack-primary text-xl" />
        )}
        {targets?.includes('APP_IOS') && (
          <AiFillApple className="stroke-seriousBlack-primary text-xl" />
        )}
      </div>
    );
  }

  return (
    <div className="overflow-x-auto w-full overflow-y-auto h-[calc(100vh-19rem)]">
      <table className="w-full">
        <thead>
          <tr>
            {headerData.map((element) => (
              <th className="text-left" key={element}>
                {element}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {bodyData?.map((survey) => {
            const formattedSinceDate = format(new Date(survey.since_time || 0), 'MMMM,dd yyyy');
            const formattedUntilDate = format(new Date(survey.until_time || 0), 'MMMM,dd yyyy');

            return (
              <tr key={survey.id}>
                <td>
                  <div className="flex flex-col">
                    <p>{survey.name ? survey.name : 'Uninformed'}</p>
                    <p className="text-sm text-seriousBlack-medium italic">
                      {survey.id ? survey.id : 'Uninformed'}
                    </p>
                  </div>
                </td>
                <td>
                  {survey.status
                    ? getStatusLabel({ status: survey.status, id: survey.id })
                    : 'Uninformed'}
                </td>
                <td>{getTargetIcons(survey.targets)}</td>
                {!!survey.since_time && !!survey.until_time ? (
                  <td>
                    {formattedSinceDate} to {formattedUntilDate}
                  </td>
                ) : (
                  'Uninformed'
                )}
                <td>
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => navigate(`/survey?id=${survey.id}`)}
                    onKeyDown={() => navigate(`/survey?id=${survey.id}`)}
                    className="w-6 h-6"
                  >
                    <AiFillEdit className="stroke-seriousBlack-primary text-2xl" />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
