import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useAuth } from '../contexts/Auth';

import AuthenticatedRoutes from './authenticated';
import PublicRoutes from './public';
import 'react-toastify/dist/ReactToastify.css';

function Routes() {
  const { hasAuthToken } = useAuth();

  return (
    <>
      {hasAuthToken() ? <AuthenticatedRoutes /> : <PublicRoutes />}
      <ToastContainer />
    </>
  );
}

export default Routes;
