import { Button, SIZES, VARIANTS } from '@bornlogic/gd-ui';
import React, { ReactNode } from 'react';

interface ContentBoxProps {
  children: ReactNode;
  handleCancelOption?: () => void;
  cancelOptionLabel?: string;
  handleConfirmOption?: () => void;
  confirmOptionLabel?: string;
}

function ContentBox({
  children,
  handleCancelOption,
  cancelOptionLabel,
  handleConfirmOption,
  confirmOptionLabel,
}: ContentBoxProps) {
  const shouldShowCancelButton = handleCancelOption && cancelOptionLabel;
  const shouldShowConfirmButton = handleConfirmOption && confirmOptionLabel;

  return (
    <div className="p-9 w-full flex gap-6 flex-col maxXl:overflow-y-auto maxMd:p-4">
      <div className="flex maxXl:flex-col gap-6 grow">{children}</div>
      {(shouldShowCancelButton || shouldShowConfirmButton) && (
        <div className="flex justify-end gap-3">
          {shouldShowCancelButton && (
            <Button
              label={cancelOptionLabel}
              size={SIZES.standard}
              onClick={handleCancelOption}
              variant={VARIANTS.red}
            />
          )}
          {shouldShowConfirmButton && (
            <Button
              label={confirmOptionLabel}
              size={SIZES.standard}
              onClick={handleConfirmOption}
              variant={VARIANTS.primary}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ContentBox;
