import React from 'react';

import { ContentBox } from 'components/atoms';
import { SurveyList } from 'components/organisms';

function Surveys() {
  return (
    <ContentBox>
      <SurveyList />
    </ContentBox>
  );
}

export default Surveys;
