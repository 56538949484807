import React from 'react';

import { ContentBox } from 'components/atoms';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CreateQuestionAndAnswers from 'components/templates/CreateQuestionAndAnswers';
import { useSurveyForm } from 'services/contexts/SurveyForm';
import * as yup from 'yup';
import validateSchema from 'utils/validation/schema';

const questionSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  type: yup.string().required('Type is required'),
  options: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required('Value is required'),
      })
    )
    .when('type', { is: 'SINGLE', then: yup.array().min(1, 'Add at least one question') }),
});

function CreateQuestion() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [id] = searchParams.getAll('id');
  const { setQuestions, questions, setQuestionErrors } = useSurveyForm();

  function cancelCreation() {
    setQuestionErrors({});
    setQuestions((state) => state.filter((question) => question.id !== id));
  }

  async function handleConfirmOption() {
    const question = questions.filter((stateQuestions) => stateQuestions.id === id)[0];

    await validateSchema({
      schema: questionSchema,
      payload: question,
      onValidationError: setQuestionErrors,
    });

    navigate(-1);
  }

  return (
    <ContentBox
      handleCancelOption={() => cancelCreation()}
      cancelOptionLabel="Delete"
      handleConfirmOption={() => handleConfirmOption()}
      confirmOptionLabel="Save"
    >
      <CreateQuestionAndAnswers />
    </ContentBox>
  );
}

export default CreateQuestion;
