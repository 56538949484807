/* eslint-disable no-param-reassign */
import http from 'axios';
import { BASE_URL } from 'utils/helpers/consts';
import { IGenericResponseUnique, Survey } from 'utils/types';

const httpInstance = http.create({});

httpInstance.interceptors.request.use(
  (config) => {
    if (config.headers) {
      config.headers['Access-Control-Allow-Origin'] = '*';
      config.headers.Authorization = `Bearer ${localStorage.userToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export function getSurveyList() {
  return new Promise<IGenericResponseUnique<Survey[]>>((resolve, reject) => {
    httpInstance.get(`${BASE_URL}/surveys/2.0/`).then(resolve).catch(reject);
  });
}

export function createSurvey(payload: Survey) {
  return new Promise<IGenericResponseUnique<Survey>>((resolve, reject) => {
    httpInstance.post(`${BASE_URL}/surveys/2.0/`, payload).then(resolve).catch(reject);
  });
}

interface UpdateStatusParams {
  status: boolean;
  id: string;
}

export function updateSurveyStatus({ status, id }: UpdateStatusParams) {
  const newStatus = status ? 'ACTIVE' : 'INACTIVE';

  return new Promise<IGenericResponseUnique<Survey>>((resolve, reject) => {
    httpInstance
      .patch(`${BASE_URL}/surveys/2.0/${id}`, { status: newStatus })
      .then(resolve)
      .catch(reject);
  });
}

interface UpdateParams {
  payload: Survey;
  id: string;
}

export function updateSurvey({ payload, id }: UpdateParams) {
  return new Promise<IGenericResponseUnique<Survey>>((resolve, reject) => {
    httpInstance.patch(`${BASE_URL}/surveys/2.0/${id}`, payload).then(resolve).catch(reject);
  });
}

export function deleteSurvey(id: string) {
  return new Promise<IGenericResponseUnique<string>>((resolve, reject) => {
    httpInstance.delete(`${BASE_URL}/surveys/2.0/${id}`).then(resolve).catch(reject);
  });
}
