const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_AUTH_URL = process.env.REACT_APP_BASE_AUTH_URL;
const GD_HOST = process.env.REACT_APP_GD_HOST;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';
const AUTH_SERVER_URL = process.env.REACT_APP_AUTH_SERVER_URL;
const IS_DEV_ENV = process.env.NODE_ENV === 'development';
const LOCALHOST_URL = process.env.REACT_APP_LOCALHOST_URL || '';
const DEFAULT_COMPANY = process.env.REACT_APP_DEFAULT_COMPANY || '';
const APP_NAME = process.env.REACT_APP_APP_NAME || 'survey';
const DEFAULT_GRAPH_API_KEY = 'data';
const ADM_CONTACT_TEXT = 'Contate seu administrador para mais informações.';
const NO_APP_TEXT = 'Nenhum aplicativo encontrado para esse negócio!';

const AUTH_PARAMS = {
  CLIENT_ID: 'client_id',
  REDIRECT_URI: 'redirect_uri',
  CODE_CHALLENGE: 'code_challenge',
  NONCE: 'nonce',
  STATE: 'state',
  CODE: 'code',
  CODE_VERIFIER: 'code_verifier',
  SESSION_STATE: 'session_state',
  ID_TOKEN_HINT: 'id_token_hint',
  POST_LOGOUT_REDIRECT_URI: 'post_logout_redirect_uri',
  REFRESH_TOKEN: 'refresh_token',
  RESPONSE_TYPE: {
    KEY: 'response_type',
    VALUE: 'code',
  },
  PROMPT: {
    KEY: 'prompt',
    VALUE: 'consent',
  },
  GRANT_TYPE: {
    KEY: 'grant_type',
    AUTH_CODE: 'authorization_code',
    REFRESH_TOKEN: 'refresh_token',
  },
  SCOPE: {
    KEY: 'scope',
    VALUE: ['email', 'openid', 'profile', 'offline_access'],
  },
  CODE_CHALLENGE_METHOD: {
    KEY: 'code_challenge_method',
    VALUE: 'S256',
  },
};

const AUTH_COOKIES = {
  STATE: 'auth_state',
  CODE_VERIFIER: 'auth_code_verifier',
};

const AUTH_TOKENS = {
  ID: 'authServerTokenID',
  SERVER: 'authServerToken',
  REFRESH: 'refreshToken',
  EXPIRATION_TIME: 'expirationTokenTime',
  BUSINESS_ID: 'businessId',
  USER_TOKEN: 'userToken',
};

const PATH_ROUTES = {
  INDEX: '/',
  LIST: '/list',
  CREATE_SURVEY: '/survey',
  CREATE_QUESTION: '/survey/question',
  CREATE_AUDIENCE: '/survey/audience',
};

const PATH_PARAMS = {
  SURVEY_ID: 'surveyId',
};

export {
  PATH_ROUTES,
  APP_NAME,
  PATH_PARAMS,
  IS_DEV_ENV,
  DEFAULT_COMPANY,
  AUTH_SERVER_URL,
  CLIENT_ID,
  LOCALHOST_URL,
  GD_HOST,
  BASE_URL,
  BASE_AUTH_URL,
  AUTH_PARAMS,
  AUTH_COOKIES,
  AUTH_TOKENS,
  DEFAULT_GRAPH_API_KEY,
  ADM_CONTACT_TEXT,
  NO_APP_TEXT,
};
