import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react';

import { getSurveyList } from 'services/survey';
import { Survey } from 'utils/types';
import { surveyListMock } from 'utils/types/survey';
import { toast } from 'react-toastify';
import { useAuth } from '../Auth';

interface SurveyContextData {
  surveyList: Survey[];
  setSurveyList: Dispatch<SetStateAction<Survey[]>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  getSurveys: () => void;
}

const SurveyContext = createContext<SurveyContextData>({} as SurveyContextData);

function SurveyProvider({ children }: { children: JSX.Element }) {
  const [surveyList, setSurveyList] = useState<Survey[]>(surveyListMock);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useAuth();

  const getSurveys = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await getSurveyList();
      setSurveyList(data);
    } catch (e) {
      toast.error('Error fetching survey list');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user.email) {
      getSurveys();
    }
  }, [user.email, getSurveys]);

  const contextValue = useMemo(
    () => ({
      surveyList,
      setSurveyList,
      isLoading,
      setIsLoading,
      getSurveys,
    }),
    [surveyList, isLoading]
  );

  return <SurveyContext.Provider value={contextValue}>{children}</SurveyContext.Provider>;
}

function useSurvey() {
  return useContext(SurveyContext);
}

export { SurveyContext, SurveyProvider, useSurvey };
