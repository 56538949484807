import CryptoJS from 'crypto-js';

import { getAuthToken } from 'services/oAuth';
import {
  IS_DEV_ENV,
  DEFAULT_COMPANY,
  AUTH_SERVER_URL,
  CLIENT_ID,
  LOCALHOST_URL,
  AUTH_PARAMS,
  AUTH_TOKENS,
  AUTH_COOKIES,
  APP_NAME,
} from './consts';
import { ITokens, UserTokenAndBusinessId } from '../types/tokens';

type CallbackParamsType = {
  code: string;
  state: string;
  session_state: string;
  scope: string;
};

type AuthCookiesType = {
  state: string;
  codeVerifier: string;
};

type HttpHeaders = {
  'Content-Type': string;
  Authorization: string;
};

function getRedirectURI() {
  return IS_DEV_ENV ? LOCALHOST_URL : `https://${APP_NAME}.${DEFAULT_COMPANY}.com`;
}

function randomStr(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  const range = Array.from(Array(length).keys());

  const finalString = range.reduce((acc) => {
    return `${acc}${characters.charAt(Math.floor(Math.random() * charactersLength))}`;
  }, '');

  return finalString;
}

function generateCodeChallenge(codeVerifier: string) {
  const sha256 = CryptoJS.SHA256(codeVerifier);
  const base64 = sha256
    .toString(CryptoJS.enc.Base64)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');

  return base64;
}

function setCookies({ state, codeVerifier }: AuthCookiesType) {
  const COOKIE_PATH = 'path=/';
  document.cookie = `${AUTH_COOKIES.STATE}=${state}; ${COOKIE_PATH}`;
  document.cookie = `${AUTH_COOKIES.CODE_VERIFIER}=${codeVerifier}; ${COOKIE_PATH}`;
}

function getCookie(cname: string) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const splitCookie = decodedCookie.split(';');
  const range = Array.from(Array(splitCookie.length).keys());

  const finalCookie = range.reduce((acc, _, index) => {
    let singleCookieChar = splitCookie[index];
    while (singleCookieChar.charAt(0) === ' ') {
      singleCookieChar = singleCookieChar.substring(1);
    }
    if (singleCookieChar.indexOf(name) === 0) {
      return `${acc}${singleCookieChar.substring(name.length, singleCookieChar.length)}`;
    }
    return acc;
  }, '');

  return finalCookie;
}

function getLoginURL({ state, codeVerifier }: AuthCookiesType) {
  const nonce = randomStr(100);
  const codeChallenge = generateCodeChallenge(codeVerifier);

  const loginUrl = new URL(`${AUTH_SERVER_URL}/connect/authorize`);

  loginUrl.searchParams.append(AUTH_PARAMS.CLIENT_ID, CLIENT_ID);
  loginUrl.searchParams.append(AUTH_PARAMS.REDIRECT_URI, getRedirectURI());
  loginUrl.searchParams.append(AUTH_PARAMS.RESPONSE_TYPE.KEY, AUTH_PARAMS.RESPONSE_TYPE.VALUE);
  loginUrl.searchParams.append(AUTH_PARAMS.PROMPT.KEY, AUTH_PARAMS.PROMPT.VALUE);
  loginUrl.searchParams.append(AUTH_PARAMS.SCOPE.KEY, AUTH_PARAMS.SCOPE.VALUE.join(' '));
  loginUrl.searchParams.append(
    AUTH_PARAMS.CODE_CHALLENGE_METHOD.KEY,
    AUTH_PARAMS.CODE_CHALLENGE_METHOD.VALUE
  );
  loginUrl.searchParams.append(AUTH_PARAMS.CODE_CHALLENGE, codeChallenge);
  loginUrl.searchParams.append(AUTH_PARAMS.NONCE, nonce);
  loginUrl.searchParams.append(AUTH_PARAMS.STATE, state);

  return loginUrl.toString();
}

function setTokens({
  authServerTokenID,
  authServerToken,
  refreshToken,
  expirationTokenTime,
}: ITokens) {
  localStorage.setItem(AUTH_TOKENS.ID, authServerTokenID);
  localStorage.setItem(AUTH_TOKENS.SERVER, authServerToken);
  localStorage.setItem(AUTH_TOKENS.REFRESH, refreshToken);
  localStorage.setItem(AUTH_TOKENS.EXPIRATION_TIME, expirationTokenTime.toString());
}

function setUserTokenAndBusinessId({ businessId, userToken }: UserTokenAndBusinessId) {
  localStorage.setItem(AUTH_TOKENS.BUSINESS_ID, businessId);
  localStorage.setItem(AUTH_TOKENS.USER_TOKEN, userToken);
}

function getTokens() {
  const tokens = {
    authServerTokenID: localStorage.getItem(AUTH_TOKENS.ID),
    authServerToken: localStorage.getItem(AUTH_TOKENS.SERVER),
    refreshToken: localStorage.getItem(AUTH_TOKENS.REFRESH),
    expirationTokenTime: Number(localStorage.getItem(AUTH_TOKENS.EXPIRATION_TIME)),
  };
  return <ITokens>tokens;
}

function getParamsForToken(code: string) {
  const params = new URLSearchParams();
  params.append(AUTH_PARAMS.CODE, code);
  params.append(AUTH_PARAMS.GRANT_TYPE.KEY, AUTH_PARAMS.GRANT_TYPE.AUTH_CODE);
  params.append(AUTH_PARAMS.CODE_VERIFIER, getCookie(AUTH_COOKIES.CODE_VERIFIER));
  params.append(AUTH_PARAMS.CLIENT_ID, CLIENT_ID);
  params.append(AUTH_PARAMS.REDIRECT_URI, getRedirectURI());
  return params;
}

function getParamsToRefreshToken(code: string) {
  const params = getParamsForToken(code);
  const { refreshToken } = getTokens();
  params.delete(AUTH_PARAMS.GRANT_TYPE.KEY);
  params.append(AUTH_PARAMS.GRANT_TYPE.KEY, AUTH_PARAMS.GRANT_TYPE.REFRESH_TOKEN);
  params.append(AUTH_PARAMS.REFRESH_TOKEN, refreshToken);
  return params;
}

function getCallbackParams() {
  const queryString = window.location.href?.split('?')[1];
  const urlParams = new URLSearchParams(queryString);

  const code = urlParams.get(AUTH_PARAMS.CODE);
  const state = urlParams.get(AUTH_PARAMS.STATE);
  const sessionState = urlParams.get(AUTH_PARAMS.SESSION_STATE);
  const scope = urlParams.get(AUTH_PARAMS.SCOPE.KEY);

  if (!code && !state) {
    return <CallbackParamsType>{};
  }

  const callbackParams = {
    code,
    state,
    session_state: sessionState,
    scope,
  };

  return <CallbackParamsType>callbackParams;
}

function isValidState(state: string) {
  return state === getCookie(AUTH_COOKIES.STATE);
}

function mountUrlToLogin() {
  const state = randomStr(100);
  const codeVerifier = randomStr(100);
  setCookies({ state, codeVerifier });
  window.location.href = getLoginURL({ state, codeVerifier });
}

function mountUrlToLogout() {
  const url = new URL(`${AUTH_SERVER_URL}/connect/endsession`);
  const tokenID = localStorage.getItem(AUTH_TOKENS.ID);

  if (tokenID) {
    url.searchParams.append(AUTH_PARAMS.ID_TOKEN_HINT, tokenID);
    url.searchParams.append(AUTH_PARAMS.POST_LOGOUT_REDIRECT_URI, getRedirectURI());
    window.location.href = url.href;
    return localStorage.clear();
  }

  window.location.href = getRedirectURI();
  return localStorage.clear();
}

function getHeaders() {
  const serverToken = localStorage.getItem(AUTH_TOKENS.SERVER);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${serverToken}`,
  };

  return <HttpHeaders>headers;
}

function hasTokenExpired(expirationTokenTime: number) {
  return Date.now() >= expirationTokenTime;
}

async function setNewToken(params: URLSearchParams) {
  try {
    const response = await getAuthToken(params);

    const {
      data: {
        id_token: authServerTokenID = '',
        access_token: authServerToken = '',
        expires_in: tokenExpirationTime = '',
        refresh_token: refreshToken = '',
      },
    } = response;

    setTokens({
      authServerTokenID,
      authServerToken,
      refreshToken,
      expirationTokenTime: (tokenExpirationTime || 0) * 1000 + Date.now(),
    });
  } catch (e) {
    mountUrlToLogout();
  }
}

export {
  setTokens,
  getTokens,
  getParamsForToken,
  getCallbackParams,
  isValidState,
  mountUrlToLogin,
  mountUrlToLogout,
  getHeaders,
  hasTokenExpired,
  getParamsToRefreshToken,
  setNewToken,
  setUserTokenAndBusinessId,
};
