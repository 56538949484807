import React, { ChangeEvent } from 'react';

import { Card, Switch } from '@bornlogic/gd-ui';
import { CardHeader } from 'components/molecules';
import { InfoBox, Row, Textarea, TextInput } from 'components/atoms';
import { useSearchParams } from 'react-router-dom';
import { useSurveyForm } from 'services/contexts/SurveyForm';
import { SelectedQuestion } from 'components/templates/CreateQuestionAndAnswers';

function Question({ selectedQuestion }: SelectedQuestion) {
  const [searchParams] = useSearchParams();
  const [id] = searchParams.getAll('id');
  const { setQuestions, questionErrors } = useSurveyForm();

  function setType(type: string) {
    setQuestions((state) =>
      state.map((question) => {
        if (question.id === id) {
          if (type === 'TEXT') {
            return { ...question, type, options_type: 'LABEL', options: [] };
          }

          return {
            ...question,
            type,
            options_type: 'LABEL',
          };
        }
        return question;
      })
    );
  }

  function setOptionType(optionType: string) {
    setQuestions((state) =>
      state.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            options_type: optionType,
            options: [],
          };
        }
        return question;
      })
    );
  }

  return (
    <Card>
      <CardHeader title="Question" hasDivider />
      <div className="w-full grow">
        <TextInput
          placeholder="Enter the question name"
          label="Name"
          value={selectedQuestion.title}
          onSearch={(event: ChangeEvent<HTMLInputElement>) =>
            setQuestions((state) =>
              state.map((question) => {
                if (question.id === id) {
                  return {
                    ...question,
                    title: event.target.value,
                  };
                }
                return question;
              })
            )
          }
          error={questionErrors.title}
        />
        <Textarea
          placeholder="What's the question description"
          label="Description"
          value={selectedQuestion.description}
          onSearch={(event: ChangeEvent<HTMLTextAreaElement>) =>
            setQuestions((state) =>
              state.map((question) => {
                if (question.id === id) {
                  return {
                    ...question,
                    description: event.target.value,
                  };
                }
                return question;
              })
            )
          }
          className="h-[50%] maxXl:h-[40%]"
          error={questionErrors.description}
        />
      </div>
      <Row>
        <Switch
          id="required"
          checked={selectedQuestion.required}
          onToggle={(checked) =>
            setQuestions((state) =>
              state.map((question) => {
                if (question.id === id) {
                  return {
                    ...question,
                    required: checked,
                  };
                }
                return question;
              })
            )
          }
          label="Required"
        />
        {selectedQuestion.options_type === 'ICON' && (
          <Switch
            id="enableEffects"
            checked={selectedQuestion.enable_effects}
            onToggle={(checked) =>
              setQuestions((state) =>
                state.map((question) => {
                  if (question.id === id) {
                    return {
                      ...question,
                      enable_effects: checked,
                    };
                  }
                  return question;
                })
              )
            }
            label="Enable Effects"
          />
        )}
      </Row>
      <Row>
        <InfoBox className="p-5 flex justify-between" label="Type" error={questionErrors.type}>
          <div className="form-control">
            <label className="label cursor-pointer">
              <input
                type="radio"
                name="radio-type"
                className="radio checked:bg-seriousBlack-primary checked:shadow-none h-5 w-5"
                onChange={() => setType('SINGLE')}
                checked={selectedQuestion.type === 'SINGLE'}
              />
              <span className="label-text text-seriousBlack-primary text-start ml-2">Single</span>
            </label>
          </div>
          <div className="form-control">
            <label className="label cursor-pointer">
              <input
                type="radio"
                name="radio-type"
                className="radio checked:bg-seriousBlack-primary checked:shadow-none h-5 w-5"
                onChange={() => setType('TEXT')}
                checked={selectedQuestion.type === 'TEXT'}
              />
              <span className="label-text text-seriousBlack-primary ml-2">Text</span>
            </label>
          </div>
        </InfoBox>
        {selectedQuestion.type !== 'TEXT' && (
          <InfoBox className="p-5 flex justify-between" label="Option Type">
            <div className="form-control">
              <label className="label cursor-pointer">
                <input
                  type="radio"
                  name="radio-optionType"
                  className="radio checked:bg-seriousBlack-primary checked:shadow-none h-5 w-5"
                  onChange={() => setOptionType('ICON')}
                  checked={selectedQuestion.options_type === 'ICON'}
                />
                <span className="label-text text-seriousBlack-primary text-start ml-2">Icon</span>
              </label>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer">
                <input
                  type="radio"
                  name="radio-optionType"
                  className="radio checked:bg-seriousBlack-primary checked:shadow-none h-5 w-5"
                  onChange={() => setOptionType('LABEL')}
                  checked={selectedQuestion.options_type === 'LABEL'}
                />
                <span className="label-text text-seriousBlack-primary ml-2">Label</span>
              </label>
            </div>
          </InfoBox>
        )}
      </Row>
    </Card>
  );
}

export default Question;
