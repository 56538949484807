import React from 'react';

import { useAuth } from 'services/contexts/Auth';

import { ExitIcon } from 'components/icons';

function SidebarMenu() {
  const { logout, user } = useAuth();

  return (
    <ul className="w-full mb-28">
      <li className="px-7 py-4 text-seriousBlack-medium border-t">{user.email}</li>
      <li className="px-7 py-4 border-t">
        <div
          role="button"
          onClick={logout}
          onKeyDown={logout}
          tabIndex={0}
          className="text-seriousBlack-medium flex items-center"
        >
          <ExitIcon className="stroke-seriousBlack-medium h-7 w-7" />
          Disconnect
        </div>
      </li>
    </ul>
  );
}

export default SidebarMenu;
