import React from 'react';

import { SurveyInfo, Questions, Audiences } from 'components/organisms';
import { ContentBox } from 'components/atoms';
import { useSearchParams } from 'react-router-dom';
import { useSurveyForm } from 'services/contexts/SurveyForm';

function CreateSurvey() {
  const { handleCreateSurvey, handleDeleteSurvey, handleUpdateSurvey } = useSurveyForm();
  const [searchParams] = useSearchParams();
  const [id] = searchParams.getAll('id');

  return (
    <ContentBox
      handleCancelOption={() => (id ? handleDeleteSurvey() : handleCreateSurvey(true))}
      cancelOptionLabel={id ? 'Delete Survey' : 'Save in Draft'}
      handleConfirmOption={() => (id ? handleUpdateSurvey() : handleCreateSurvey())}
      confirmOptionLabel={id ? 'Save' : 'Publish'}
    >
      <SurveyInfo />
      <Questions />
      <Audiences />
    </ContentBox>
  );
}

export default CreateSurvey;
