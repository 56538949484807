import React, { ChangeEventHandler, KeyboardEvent, useMemo, forwardRef } from 'react';

interface InputProps {
  onSearch: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  placeholder?: string;
  label?: string;
  error?: string;
  onPressEnter?: () => void;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder, onSearch, value = '', label, onPressEnter, error }: InputProps, ref) => {
    const inputId = useMemo(() => `labelId-${label}`, [label]);

    function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
      if (event.key === 'Enter' && onPressEnter) {
        onPressEnter();
      }
    }

    const labelColor = useMemo(() => {
      return error ? 'warningRed-primary' : 'seriousBlack-primary';
    }, [error]);

    return (
      <div className="form-control w-full">
        {label && (
          <label className="label" htmlFor={inputId}>
            <span className={`label-text text-${labelColor}`}>{label}</span>
          </label>
        )}
        <input
          type="text"
          id={inputId}
          placeholder={placeholder}
          className="input input-bordered w-full bg-transparent border-seriousBlack-lighter1 placeholder:border-seriousBlack-lighter1"
          onChange={onSearch}
          onKeyDown={handleKeyDown}
          value={value}
          ref={ref}
        />
        {!!error && (
          <label className="label" htmlFor={inputId}>
            <span className="label-text text-warningRed-primary">{error}</span>
          </label>
        )}
      </div>
    );
  }
);

export default Input;
