import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Surveys, CreateSurvey, CreateQuestion } from 'pages';
import { PATH_ROUTES } from 'utils/helpers/consts';
import { PageHeader } from 'components/molecules';
import { Sidebar } from 'components/atoms';
import CreateAudience from 'pages/CreateAudience';

function AuthenticatedRoutes() {
  return (
    <>
      <PageHeader />
      <div className="flex flex-row grow h-[calc(100vh-6rem)]">
        <Sidebar />
        <Routes>
          <Route path={PATH_ROUTES.LIST} element={<Surveys />} />
          <Route path={PATH_ROUTES.CREATE_SURVEY} element={<CreateSurvey />} />
          <Route path={PATH_ROUTES.CREATE_QUESTION} element={<CreateQuestion />} />
          <Route path={PATH_ROUTES.CREATE_AUDIENCE} element={<CreateAudience />} />
          <Route path="*" element={<Navigate to={PATH_ROUTES.LIST} replace />} />
        </Routes>
      </div>
    </>
  );
}

export default AuthenticatedRoutes;
