import * as yup from 'yup';
import { toast } from 'react-toastify';

type ErrorObject = {
  [field: string]: string;
};

interface ValidateProps<T> {
  schema: yup.AnySchema;
  payload: T;
  onValidationError?: (...any: any) => any;
}

async function validateSchema<T>({
  schema,
  payload,
  onValidationError = () => null,
}: ValidateProps<T>) {
  try {
    const validation = await schema.validate(payload, { abortEarly: false });
    onValidationError({});
    return validation;
  } catch (err: unknown) {
    if (err instanceof yup.ValidationError) {
      const errors: ErrorObject = {};

      err.inner.forEach((x) => {
        if (x.path !== undefined) {
          errors[x.path] = x.errors[0];
        }
      });

      onValidationError(errors);
      toast.error('Error in validation');
      throw Error('Validation Error');
    }
    throw err;
  }
}

export default validateSchema;
