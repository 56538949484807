import React, { ChangeEvent } from 'react';

import { Card } from '@bornlogic/gd-ui';
import { Select } from 'react-daisyui';
import { CardHeader } from 'components/molecules';
import { InfoBox, TextInput } from 'components/atoms';
import { TrashCanIcon } from 'components/icons';
import { SelectedQuestion } from 'components/templates/CreateQuestionAndAnswers';
import { Option, Question } from 'utils/types';
import { useSurveyForm } from 'services/contexts/SurveyForm';
import { useSearchParams } from 'react-router-dom';

const iconOptions = [
  {
    value: 'STAR',
    label: 'Star',
  },
  {
    value: 'HEART',
    label: 'Heart',
  },
  {
    value: 'LIKE',
    label: 'Like',
  },
  {
    value: 'DISLIKE',
    label: 'Dislike',
  },
];

function Answers({ selectedQuestion }: SelectedQuestion) {
  const { setQuestions, questionErrors } = useSurveyForm();
  const [searchParams] = useSearchParams();
  const [id] = searchParams.getAll('id');

  function getNextId(answerList: Option[]) {
    const last = answerList[answerList.length - 1];
    const nextId = last ? `${Number(last.id) + 1}` : '1';

    return nextId;
  }

  function removeOption(optionId: string) {
    setQuestions((state) =>
      state.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            options: question.options.filter((option) => option.id !== optionId),
          };
        }
        return question;
      })
    );
  }

  function getNewQuestion(question: Question) {
    if (question.options_type === 'ICON') {
      return { value: 'STAR', description: '', id: getNextId(question.options) };
    }

    return { value: '', description: '', id: getNextId(question.options) };
  }

  return (
    <Card>
      <CardHeader
        title="Answers"
        rightButtonLabel="Add Answer"
        hasDivider
        onClickRightButton={() =>
          setQuestions((state) =>
            state.map((question) => {
              if (question.id === id) {
                return {
                  ...question,
                  options: [...question.options, getNewQuestion(question)],
                };
              }
              return question;
            })
          )
        }
      />
      <div className="overflow-y-auto w-full h-[calc(100vh-24rem)]">
        {!selectedQuestion.options?.length && questionErrors.options && (
          <span className="text-warningRed-primary">{questionErrors.options}</span>
        )}
        {selectedQuestion.options_type === 'ICON' &&
          selectedQuestion.options?.map((answer, index) => (
            <InfoBox
              className="p-3 flex justify-between gap-3"
              key={answer.id}
              error={questionErrors[`options[${index}].value`]}
            >
              <Select
                className="w-full rounded-lg border-seriousBlack-lighter1 bg-primaryWhite border"
                onChange={(value: string) => {
                  setQuestions((state) =>
                    state.map((question) => {
                      if (question.id === id) {
                        return {
                          ...question,
                          options: question.options.map((option) => {
                            if (option.id !== answer.id) {
                              return option;
                            }

                            return { ...option, value };
                          }),
                        };
                      }
                      return question;
                    })
                  );
                }}
                initialValue={answer.value}
              >
                {iconOptions.map((option) => (
                  <Select.Option value={option.value} key={option.value} tabIndex={0}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
              <TextInput
                value={answer.description}
                onSearch={(event: ChangeEvent<HTMLInputElement>) =>
                  setQuestions((state) =>
                    state.map((question) => {
                      if (question.id === id) {
                        return {
                          ...question,
                          options: question.options.map((option) => {
                            if (option.id !== answer.id) {
                              return option;
                            }

                            return { ...option, description: event?.target.value };
                          }),
                        };
                      }
                      return question;
                    })
                  )
                }
              />
              <button
                onClick={() => removeOption(answer.id)}
                type="button"
                className="btn-link btn-primary p-2"
              >
                <TrashCanIcon className="stroke-seriousBlack-primary h-7 w-7" />
              </button>
            </InfoBox>
          ))}
        {selectedQuestion.options_type === 'LABEL' &&
          selectedQuestion.options?.map((answer, index) => (
            <InfoBox
              className="p-3 flex justify-between gap-3"
              key={answer.id}
              error={questionErrors[`options[${index}].value`]}
            >
              <TextInput
                value={answer.value}
                onSearch={(event: ChangeEvent<HTMLInputElement>) =>
                  setQuestions((state) =>
                    state.map((question) => {
                      if (question.id === id) {
                        return {
                          ...question,
                          options: question.options.map((option) => {
                            if (option.id !== answer.id) {
                              return option;
                            }

                            return {
                              ...option,
                              value: event.target.value,
                              description: event.target.value,
                            };
                          }),
                        };
                      }
                      return question;
                    })
                  )
                }
              />
              <button
                onClick={() => removeOption(answer.id)}
                type="button"
                className="btn-link btn-primary p-2"
              >
                <TrashCanIcon className="stroke-seriousBlack-primary h-7 w-7" />
              </button>
            </InfoBox>
          ))}
      </div>
    </Card>
  );
}

export default Answers;
