import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Auth } from 'pages';
import { PATH_ROUTES } from 'utils/helpers/consts';

function PublicRoutes() {
  return (
    <Routes>
      <Route path={PATH_ROUTES.INDEX} element={<Auth />} />
      <Route path="*" element={<Navigate to={PATH_ROUTES.INDEX} replace />} />
    </Routes>
  );
}

export default PublicRoutes;
