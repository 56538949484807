import React from 'react';

import { Card } from '@bornlogic/gd-ui';
import { InfoBox } from 'components/atoms';
import { CardHeader } from 'components/molecules';
import { useNavigate } from 'react-router-dom';
import { useSurveyForm } from 'services/contexts/SurveyForm';
import Enter from 'components/icons/Enter';

function Questions() {
  const navigate = useNavigate();
  const { questions, setQuestions, infoErrors } = useSurveyForm();

  function setCreateQuestions() {
    const nextId = Number(questions.at(-1)?.id) + 1 || 1;

    setQuestions((state) => [
      ...state,
      {
        id: String(nextId),
        type: 'SINGLE',
        description: '',
        enable_effects: false,
        options: [],
        options_type: 'LABEL',
        required: false,
        title: '',
      },
    ]);

    navigate(`/survey/question?id=${nextId}`);
  }

  return (
    <Card>
      <CardHeader
        title="Questions"
        hasDivider
        onClickRightButton={() => setCreateQuestions()}
        rightButtonLabel="Add Question"
      />
      <div className="w-full overflow-y-auto h-[calc(100vh-21rem)]">
        {questions.length > 0
          ? questions.map((question) => (
              <InfoBox key={question.id} className="flex items-center justify-between p-8">
                <div className="flex flex-col justify-between gap-8">
                  <div className="w-60">
                    <h3 className="font-bold text-xl truncate">{question.title}</h3>
                    <h4 className="font-medium text-lg truncate">{question.description}</h4>
                  </div>

                  {!!question.options?.length && (
                    <div className="flex flex-col">
                      Answers:
                      <ul>
                        {question.options.map((answer) => (
                          <li key={answer.id}>{answer.description || answer.value}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => navigate(`/survey/question?id=${question.id}`)}
                  onKeyDown={() => navigate(`/survey/question?id=${question.id}`)}
                  className="w-8 h-8"
                >
                  <Enter className="stroke-seriousBlack-primary" />
                </div>
              </InfoBox>
            ))
          : !!infoErrors.questions && (
              <span className="text-warningRed-primary">{infoErrors.questions}</span>
            )}
      </div>
    </Card>
  );
}

export default Questions;
