export interface Option {
  description: string;
  id: string;
  value: string;
}
export interface GetStatusLabelParams {
  status: string;
  id: string;
}

export enum TriggerTypes {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export interface Trigger {
  id?: string;
  type: TriggerTypes;
  event_name: string;
  card?: Card;
}

export interface Question {
  description: string;
  enable_effects: boolean;
  id: string;
  options: Option[];
  options_type: string;
  required: boolean;
  title: string;
  type: string;
}

export interface Card {
  subtitle?: string;
  title?: string;
}

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT',
}

export interface Survey {
  allowed_tenants?: string[];
  allowed_users?: string[];
  contact_confirmation: boolean;
  created_time?: string;
  updated_time?: string;
  since_time?: string;
  until_time?: string;
  description: string;
  display_type: string;
  id: string;
  name: string;
  questions: Question[];
  status: Status;
  targets: string[];
  type: string;
  trigger?: Trigger;
}

export const surveyListMock: Survey[] = [
  {
    allowed_tenants: ['string'],
    allowed_users: ['string'],
    contact_confirmation: true,
    created_time: 'string',
    description: 'string',
    display_type: 'ALL_AT_ONCE',
    id: '1',
    name: 'danilo',
    questions: [
      {
        description: 'seestringstringstringststringstringstringstringstringstringstringringtring',
        enable_effects: true,
        id: '1',
        options: [
          {
            description: 'HEART',
            id: '1',
            value: 'Gostei',
          },
        ],
        options_type: 'ICON',
        required: true,
        title: 'string',
        type: 'TEXT',
      },
    ],
    status: Status.DRAFT,
    targets: ['WEB', 'APP_ANDROID'],
    trigger: {
      card: {
        subtitle: 'string',
        title: 'string',
      },
      event_name: 'string',
      type: TriggerTypes.AUTO,
    },
    type: 'FULL_SCREEN',
    updated_time: 'string',
  },
  {
    allowed_tenants: ['sim', 'man', 'claro'],
    allowed_users: ['ctz', 'com', 'certezaaaa'],
    contact_confirmation: true,
    created_time: 'string',
    description: 'string',
    display_type: 'string',
    id: '2',
    name: 'born',
    questions: [
      {
        description: 'string',
        enable_effects: true,
        id: '1',
        options: [
          {
            description: 'string',
            id: '1',
            value: 'string',
          },
        ],
        options_type: 'string',
        required: true,
        title: 'string',
        type: 'string',
      },
    ],
    status: Status.INACTIVE,
    targets: ['string'],
    trigger: {
      card: {
        subtitle: 'string',
        title: 'string',
      },
      event_name: 'string',
      type: TriggerTypes.MANUAL,
    },
    type: 'string',
    updated_time: 'string',
  },
  {
    allowed_tenants: ['string'],
    allowed_users: ['string'],
    contact_confirmation: true,
    created_time: 'string',
    description: 'string',
    display_type: 'string',
    id: '3',
    name: 'logic',
    questions: [
      {
        description: 'string',
        enable_effects: true,
        id: '1',
        options: [
          {
            description: 'string',
            id: '1',
            value: 'string',
          },
        ],
        options_type: 'string',
        required: true,
        title: 'string',
        type: 'string',
      },
    ],
    status: Status.ACTIVE,
    targets: ['web', 'ios'],
    trigger: {
      card: {
        subtitle: 'string',
        title: 'string',
      },
      event_name: 'string',
      type: TriggerTypes.MANUAL,
    },
    type: 'string',
    updated_time: 'string',
  },
  {
    id: '235d8113-8f5d-4793-8cfc-4f328f0c9154',
    name: 'danilo',
    description: 'string',
    allowed_tenants: ['string'],
    allowed_users: ['string'],
    status: Status.ACTIVE,
    created_time: '2022-08-18T19:44:31Z',
    updated_time: '2022-08-18T19:44:31Z',
    targets: ['APP_ANDROID', 'WEB'],
    type: 'FULL_SCREEN',
    display_type: 'ALL_AT_ONCE',
    contact_confirmation: true,
    trigger: {
      card: {
        subtitle: 'string',
        title: 'string',
      },
      event_name: 'string',
      type: TriggerTypes.MANUAL,
    },
    questions: [
      {
        id: '2f7e84f9-bef5-45d7-bb8e-707d4929f27f',
        title: 'string',
        description: 'seestringstringstringststringstringstringstringstringstringstringringtring',
        type: 'TEXT',
        required: true,
        enable_effects: true,
        options_type: 'ICON',
        options: [
          {
            id: 'd571883c-1bde-415b-86ab-609ed6cd40f8',
            value: 'HEART',
            description: 'amei',
          },
          {
            id: 'd338bf2d-1fd4-44cf-9f6a-8ea7fd256d3e',
            value: 'STAR',
            description: 'RUIM',
          },
          {
            id: 'a2db1443-3317-4867-a3ed-33f0b2761370',
            value: 'STAR',
            description: 'MÉDIA',
          },
          {
            id: '40be46de-a3cb-4220-988c-ae2652fe3ac8',
            value: 'STAR',
            description: 'BOA',
          },
          {
            id: 'cd228986-1a58-40eb-81bd-8df0023d5871',
            value: 'STAR',
            description: 'MUITO BOA',
          },
          {
            id: 'b1f0dc5a-77c6-41c8-81b6-a5123dbafeb4',
            value: 'STAR',
            description: 'ÓTIMA',
          },
        ],
      },
    ],
  },
];
