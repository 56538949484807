import React, { ChangeEventHandler, useMemo } from 'react';

interface TextAreaProps {
  onSearch: ChangeEventHandler<HTMLTextAreaElement>;
  value: string;
  placeholder?: string;
  label?: string;
  className?: string;
  error?: string;
}

function TextArea({ placeholder, onSearch, value, label, className, error }: TextAreaProps) {
  const textAreaId = useMemo(() => `labelId-${label}`, [label]);

  const labelColor = useMemo(() => {
    return error ? 'warningRed-primary' : 'seriousBlack-primary';
  }, [error]);

  return (
    <div className={`form-control w-full ${className}`}>
      {label && (
        <label className="label" htmlFor={textAreaId}>
          <span className={`label-text text-${labelColor}`}>{label}</span>
        </label>
      )}
      <textarea
        id={textAreaId}
        placeholder={placeholder}
        className="textarea textarea-bordered w-full bg-transparent border-seriousBlack-lighter1 placeholder:border-seriousBlack-lighter1 h-full"
        onChange={onSearch}
        value={value}
      />
      {!!error && (
        <label className="label" htmlFor={textAreaId}>
          <span className="label-text text-warningRed-primary">{error}</span>
        </label>
      )}
    </div>
  );
}

export default TextArea;
