import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { createSurvey, deleteSurvey, updateSurvey, updateSurveyStatus } from 'services/survey';
import { Question } from 'utils/types';
import { Status, TriggerTypes } from 'utils/types/survey';
import type { Trigger, Survey } from 'utils/types/survey';
import { Range } from 'react-date-range';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import validateSchema from 'utils/validation/schema';
import { useSurvey } from '../Survey';

interface Audience {
  type: string;
  data: string[];
  id: string;
}

export interface TargetOptions {
  [target: string]: boolean;
}

interface SurveyInfo {
  name: string;
  description: string;
  targetOptions: TargetOptions;
  type: string;
  contactConfirmation: boolean;
  displayType: string;
  trigger: Trigger;
  status: Status;
  range: Range[];
}

interface UpdateStatusParams {
  surveyId: string;
  status: boolean;
}

interface SurveyFormContextData {
  audiences: Audience[];
  setAudiences: Dispatch<SetStateAction<Audience[]>>;
  questions: Question[];
  setQuestions: Dispatch<SetStateAction<Question[]>>;
  info: SurveyInfo;
  setInfo: Dispatch<SetStateAction<SurveyInfo>>;
  infoErrors: Error;
  setInfoErrors: Dispatch<SetStateAction<Error>>;
  questionErrors: Error;
  setQuestionErrors: Dispatch<SetStateAction<Error>>;
  handleCreateSurvey: (isDraft?: boolean) => void;
  handleDeleteSurvey: () => void;
  handleUpdateSurvey: () => void;
  handleUpdateSurveyStatus: (params: UpdateStatusParams) => void;
}

export interface Error {
  [key: string]: string;
}

const SurveyFormContext = createContext<SurveyFormContextData>({} as SurveyFormContextData);

const initialInfo: SurveyInfo = {
  name: '',
  description: '',
  targetOptions: {
    APP_IOS: false,
    APP_ANDROID: false,
    WEB: false,
  },
  contactConfirmation: false,
  displayType: '',
  type: '',
  status: Status.ACTIVE,
  range: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ],
  trigger: {
    event_name: '',
    id: '1',
    type: TriggerTypes.MANUAL,
    card: {
      title: '',
      subtitle: '',
    },
  },
};

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  targets: yup.array().min(1, 'Specify at least one target'),
  questions: yup.array().min(1, 'Create at least one question'),
  allowed_tenants: yup.array().min(1, 'Add at least one tenant'),
  trigger: yup.object().shape({
    type: yup.string(),
    card: yup.object().when('type', {
      is: 'MANUAL',
      then: yup.object().shape({
        title: yup.string().required('Specify the card title'),
        subtitle: yup.string().required('Specify the card subtitle'),
      }),
    }),
  }),
  type: yup.string().required('Type is required'),
  display_type: yup.string().required('Display type is required'),
});

function SurveyFormProvider({ children }: { children: JSX.Element }) {
  const [audiences, setAudiences] = useState<Audience[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [info, setInfo] = useState<SurveyInfo>(initialInfo);
  const [infoErrors, setInfoErrors] = useState<Error>({});
  const [questionErrors, setQuestionErrors] = useState<Error>({});

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isSurvey = useMemo(() => {
    const locationArray = location.pathname.split('/');

    return locationArray[1] === 'survey' && locationArray.length === 2;
  }, [location.pathname]);
  const [id] = searchParams.getAll('id');
  const [atualized, setAtualized] = useState(!id && isSurvey);
  const { surveyList, getSurveys } = useSurvey();

  function clearContext() {
    setAudiences([]);
    setQuestions([]);
    setInfo(initialInfo);
    setAtualized(false);
  }

  useEffect(() => {
    if (!location.pathname.split('/').includes('survey') && !id) {
      clearContext();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (id && isSurvey && !atualized) {
      const selectedSurvey = surveyList.filter((survey) => survey.id === id)[0];
      if (!selectedSurvey) {
        navigate(-1);
        return;
      }
      const defaultTargets: TargetOptions = {
        APP_IOS: false,
        APP_ANDROID: false,
        WEB: false,
      };
      selectedSurvey.targets?.forEach((target: string) => {
        if (Object.keys(defaultTargets).includes(target)) {
          defaultTargets[target] = true;
        }
      });
      const selectedSurveyRange = {
        startDate: selectedSurvey.since_time ? new Date(selectedSurvey.since_time) : new Date(),
        endDate: selectedSurvey.until_time ? new Date(selectedSurvey.until_time) : new Date(),
      };
      setInfo({
        contactConfirmation: selectedSurvey.contact_confirmation,
        description: selectedSurvey.description,
        name: selectedSurvey.name,
        targetOptions: defaultTargets,
        type: selectedSurvey.type,
        displayType: selectedSurvey.display_type,
        trigger: selectedSurvey.trigger || { type: TriggerTypes.MANUAL, event_name: '' },
        status: selectedSurvey.status || Status.ACTIVE,
        range: [
          {
            ...selectedSurveyRange,
            key: 'selection',
          },
        ],
      });

      setAudiences([
        { type: 'user', data: selectedSurvey.allowed_users || [], id: '1' },
        { type: 'tenant', data: selectedSurvey.allowed_tenants || [], id: '2' },
      ]);

      const options: Question[] = [];
      selectedSurvey.questions.forEach((question) => {
        const payload: Question = {
          description: question.description,
          enable_effects: question.enable_effects,
          id: question.id,
          options: question.options,
          options_type: question.options_type,
          required: question.required,
          title: question.title,
          type: question.type,
        };

        options.push(payload);
      });

      setQuestions(options);
      setAtualized(true);
    }
  }, [searchParams]);

  async function getPayload(isDraft?: boolean) {
    const allowedTenants = audiences
      .filter((audience) => audience.type === 'tenant')
      .map((filteredAudience) => filteredAudience.data)
      .flat()
      .map((tenant) => tenant.trim());
    const allowedUsers = audiences
      .filter((audience) => audience.type === 'user')
      .map((filteredAudience) => filteredAudience.data)
      .flat()
      .map((user) => user.trim());
    const targetOptions = Object.entries(info.targetOptions);
    const targets = targetOptions
      .filter((targetOption) => targetOption[1])
      .map((filteredOption) => filteredOption[0]);
    const surveyId = window.location.href.split('survey?id=')[1];

    const payload: Survey = {
      allowed_tenants: allowedTenants,
      allowed_users: allowedUsers,
      contact_confirmation: info.contactConfirmation,
      description: info.description,
      name: info.name,
      type: info.type,
      status: isDraft ? Status.DRAFT : info.status,
      targets,
      id: surveyId,
      display_type: info.displayType,
      questions,
      trigger: info.trigger,
      since_time: info.range[0].startDate?.toISOString(),
      until_time: info.range[0].endDate?.toISOString(),
    };

    await validateSchema({ schema, payload, onValidationError: setInfoErrors });

    return payload;
  }

  function clearErrors() {
    setQuestionErrors({});
    setInfoErrors({});
  }

  async function handleCreateSurvey(isDraft?: boolean) {
    const payload = await getPayload(isDraft);
    await toast.promise(createSurvey(payload), {
      pending: 'Creating survey',
      error: 'An error occurred when creating the survey',
      success: 'Survey was created successfully',
    });
    clearErrors();
    navigate(-1);
    getSurveys();
  }

  async function handleUpdateSurveyStatus({ status, surveyId }: UpdateStatusParams) {
    await toast.promise(updateSurveyStatus({ status, id: surveyId }), {
      pending: 'Updating survey',
      error: 'An error occurred when updating the survey',
      success: 'Survey was updated successfully',
    });
    getSurveys();
  }

  async function handleUpdateSurvey() {
    const payload = await getPayload();
    await toast.promise(updateSurvey({ payload, id: payload.id }), {
      pending: 'Updating survey',
      error: 'An error occurred when updating the survey',
      success: 'Survey was updated successfully',
    });
    clearErrors();
    navigate(-1);
    getSurveys();
  }

  async function handleDeleteSurvey() {
    await toast.promise(deleteSurvey(id), {
      pending: 'Deleting survey',
      error: 'Survey was not deleted',
      success: 'Survey was deleted successfully',
    });
    clearErrors();
    navigate(-1);
    getSurveys();
  }

  const contextValue = useMemo(
    () => ({
      audiences,
      setAudiences,
      questions,
      setQuestions,
      info,
      setInfo,
      infoErrors,
      setInfoErrors,
      questionErrors,
      setQuestionErrors,
      handleCreateSurvey,
      handleDeleteSurvey,
      handleUpdateSurvey,
      handleUpdateSurveyStatus,
    }),
    [audiences, questions, info, infoErrors, questionErrors]
  );

  return <SurveyFormContext.Provider value={contextValue}>{children}</SurveyFormContext.Provider>;
}

function useSurveyForm() {
  return useContext(SurveyFormContext);
}

export { SurveyFormContext, SurveyFormProvider, useSurveyForm };
