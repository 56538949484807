import React, { ReactNode } from 'react';

interface InfoBoxProps {
  children: ReactNode;
  className?: string;
  containerClassName?: string;
  label?: string;
  error?: string;
}

const getLabelColor = (error?: string) => (error ? 'warningRed-primary' : 'seriousBlack-primary');
const getBorderColor = (error?: string) => (error ? 'warningRed-primary' : 'seriousBlack-lighter1');

function InfoBox({ children, label, className, containerClassName, error }: InfoBoxProps) {
  return (
    <div className={containerClassName}>
      {label && <span className={`label-text text-${getLabelColor(error)}`}>{label}</span>}
      <div className={`rounded-lg border-${getBorderColor(error)} border w-full my-3 ${className}`}>
        {children}
      </div>
      {!!error && <span className="text-warningRed-primary">{error}</span>}
    </div>
  );
}

export default InfoBox;
