import React from 'react';
import { IconProps } from '..';

function Exit({ className }: IconProps) {
  return (
    <svg viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M1.79845 0.199951H5.09845C5.26345 0.199951 5.39845 0.334951 5.39845 0.499951V0.699951C5.39845 0.864951 5.26345 0.999951 5.09845 0.999951H1.79845C1.57845 0.999951 1.39845 1.17995 1.39845 1.39995V8.59995C1.39845 8.81995 1.57845 8.99995 1.79845 8.99995H5.09845C5.26345 8.99995 5.39845 9.13495 5.39845 9.29995V9.49995C5.39845 9.66495 5.26345 9.79995 5.09845 9.79995H1.79845C1.13595 9.79995 0.59845 9.26245 0.59845 8.59995V1.39995C0.59845 0.737451 1.13595 0.199951 1.79845 0.199951ZM8.77345 0.687451L8.59595 0.864951C8.47845 0.982451 8.47845 1.17245 8.59595 1.28995L11.896 4.57495H4.89845C4.73345 4.57495 4.59845 4.70995 4.59845 4.87495V5.12495C4.59845 5.28995 4.73345 5.42495 4.89845 5.42495H11.896L8.59845 8.70995C8.48095 8.82745 8.48095 9.01745 8.59845 9.13495L8.77595 9.31245C8.89345 9.42995 9.08345 9.42995 9.20095 9.31245L13.3135 5.21245C13.431 5.09495 13.431 4.90495 13.3135 4.78745L9.19845 0.687451C9.08095 0.569951 8.89095 0.569951 8.77345 0.687451Z" />
    </svg>
  );
}

export default Exit;
