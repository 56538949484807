import React from 'react';

interface ImageProps {
  src: string;
  alt: string;
  customStyle?: string;
  onClick?: () => void;
}

function Image({ src, alt, customStyle = '', onClick }: ImageProps) {
  return (
    <div role="button" onClick={onClick} onKeyDown={onClick} tabIndex={0}>
      <img src={src} alt={alt} className={`bg-cover bg-center bg-origin-border ${customStyle}`} />
    </div>
  );
}

export default Image;
