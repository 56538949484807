import React, { useMemo } from 'react';

interface CardHeaderProps {
  title: string;
  onClickRightButton?: () => void;
  rightButtonLabel?: string;
  hasDivider?: boolean;
}

function CardHeader({
  title,
  rightButtonLabel,
  onClickRightButton,
  hasDivider = false,
}: CardHeaderProps) {
  const rightHeader = useMemo(() => {
    if (rightButtonLabel && onClickRightButton) {
      return (
        <div className="flex items-center">
          <button
            type="button"
            onClick={onClickRightButton}
            className="btn btn-link text-securityBlue-primary normal-case font-normal pr-2"
          >
            {rightButtonLabel}
          </button>
        </div>
      );
    }

    return null;
  }, []);

  return (
    <div className="flex flex-col justify-between w-full">
      <header className="flex justify-between w-full maxSm:flex-col">
        <h2 className="text-4xl font-bold">{title}</h2>

        {rightHeader}
      </header>
      {hasDivider && <div className="divider mt-0" />}
    </div>
  );
}

export default CardHeader;
