import React from 'react';

function LoginTitle() {
  return (
    <div className="flex flex-col items-center mb-20 gap-4">
      <h2 className="text-4xl font-medium">Survey Administration</h2>
      <h4>Gerencie as pesquisas de CES, CSAT, NPS nas plataformas de Gerentes Digitais.</h4>
    </div>
  );
}

export default LoginTitle;
