import React, { ChangeEvent, createRef, useEffect, useMemo, useState } from 'react';

import { Card, Icon } from '@bornlogic/gd-ui';
import { CardHeader } from 'components/molecules';
import { InfoBox, TextInput } from 'components/atoms';
import { TrashCanIcon } from 'components/icons';
import { useSurveyForm } from 'services/contexts/SurveyForm';
import { useNavigate, useSearchParams } from 'react-router-dom';

const defaultAudience = {
  id: 0,
  type: '',
  data: [],
};

function Audience() {
  const dataInputRef = createRef<HTMLInputElement>();
  const { setAudiences, audiences } = useSurveyForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [id] = searchParams.getAll('id');
  const [newData, setNewData] = useState('');

  const selectedAudience = useMemo(() => {
    return audiences.find((audience) => audience.id === id) || defaultAudience;
  }, [audiences]);

  useEffect(() => {
    if (!selectedAudience.id) {
      navigate(-1);
    }
  }, [id, selectedAudience.id]);

  useEffect(() => {
    if (newData === ' ') {
      dataInputRef.current?.focus();
    }
  }, [newData]);

  function addNewData() {
    setAudiences((state) =>
      state.map((audience) => {
        if (audience.id === id) {
          const payload = newData.trim() ? [...audience.data, newData] : audience.data;

          return {
            ...audience,
            data: payload,
          };
        }
        return audience;
      })
    );
    if (newData === ' ') {
      setNewData('');
    } else {
      setNewData(' ');
    }
  }

  function changeType(newType: string) {
    setAudiences((state) =>
      state.map((audience) => {
        if (audience.id === id) {
          return {
            data: [],
            type: newType,
            id,
          };
        }
        return audience;
      })
    );
  }

  return (
    <div className="w-1/2 maxXl:w-full">
      <Card>
        <CardHeader title="Audience" hasDivider />
        <div className="flex items-center w-full gap-8 overflow-x-auto">
          <span className="label-text text-seriousBlack-primary">Type</span>
          <InfoBox className="flex grow p-2">
            <div className="form-control">
              <label className="label cursor-pointer">
                <input
                  type="radio"
                  name="radio-type"
                  className="radio checked:bg-seriousBlack-primary checked:shadow-none h-5 w-5"
                  onChange={() => changeType('tenant')}
                  checked={selectedAudience.type === 'tenant'}
                />
                <span className="label-text text-seriousBlack-primary text-start ml-2">Tenant</span>
              </label>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer">
                <input
                  type="radio"
                  name="radio-type"
                  className="radio checked:bg-seriousBlack-primary checked:shadow-none h-5 w-5"
                  onChange={() => changeType('user')}
                  checked={selectedAudience.type === 'user'}
                />
                <span className="label-text text-seriousBlack-primary ml-2">User</span>
              </label>
            </div>
          </InfoBox>
        </div>
        <div className="w-full">
          {!!selectedAudience.type && (
            <div className="flex justify-between items-center">
              <span className="label-text text-seriousBlack-primary">
                {selectedAudience.type === 'tenant' ? 'Tenants' : 'Users'}
              </span>
              <button
                type="button"
                onClick={() => {
                  setNewData(' ');
                }}
                className="btn btn-link text-securityBlue-primary normal-case font-normal"
              >
                Add
                {selectedAudience.type === 'tenant' ? ' tenant' : ' user'}
              </button>
            </div>
          )}
          <div className="overflow-y-auto h-[calc(100vh-30rem)]">
            {!!selectedAudience.data?.length && (
              <ul>
                {selectedAudience.data?.map((value) => (
                  <InfoBox className="flex justify-between items-center p-2 " key={value}>
                    <span>{value}</span>
                    <button
                      onClick={() =>
                        setAudiences((state) =>
                          state.map((audience) => {
                            if (audience.id === id) {
                              return {
                                ...audience,
                                data: audience.data.filter((name) => name !== value),
                              };
                            }
                            return audience;
                          })
                        )
                      }
                      type="button"
                      className="btn-link btn-primary p-2"
                    >
                      <TrashCanIcon className="stroke-seriousBlack-primary h-7 w-7" />
                    </button>
                  </InfoBox>
                ))}
              </ul>
            )}
            {!!newData && (
              <div className="flex m-1">
                <TextInput
                  value={newData}
                  onSearch={(event: ChangeEvent<HTMLInputElement>) =>
                    setNewData(event.target.value)
                  }
                  onPressEnter={() => addNewData()}
                  ref={dataInputRef}
                />
                <button
                  className="btn btn-primary btn-link text-seriousBlack-primary"
                  type="button"
                  onClick={addNewData}
                >
                  <Icon icon={newData.trim() ? 'check' : 'close'} />
                </button>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Audience;
