import React, { ChangeEvent } from 'react';

import { Card, Switch } from '@bornlogic/gd-ui';
import { CardHeader } from 'components/molecules';
import { InfoBox, Row, Textarea, TextInput, DateRange } from 'components/atoms';
import { useSurveyForm } from 'services/contexts/SurveyForm';
import { TriggerTypes } from 'utils/types/survey';

const labelColor = (error?: string) => (error ? 'warningRed-primary' : 'seriousBlack-primary');

function SurveyInfo() {
  const { info, setInfo, infoErrors } = useSurveyForm();

  return (
    <Card>
      <CardHeader title="Survey Info" hasDivider />
      <div className="overflow-y-auto h-[calc(100vh-21rem)] w-full pr-2">
        <div className="w-full grow mb-4">
          <TextInput
            placeholder="Enter the survey name"
            label="Name"
            value={info.name}
            onSearch={(event: ChangeEvent<HTMLInputElement>) =>
              setInfo((state) => ({ ...state, name: event.target.value }))
            }
            error={infoErrors.name}
          />
          <Textarea
            placeholder="What's the survey usage"
            label="Description"
            value={info.description}
            onSearch={(event: ChangeEvent<HTMLTextAreaElement>) =>
              setInfo((state) => ({ ...state, description: event.target.value }))
            }
            error={infoErrors.description}
          />
        </div>
        <Row className="flex-col maxXl:flex-row maxMd:flex-col">
          <div className="flex gap-2 grow flex-col w-full maxXl:w-[50%] maxMd:w-full">
            <span className={`label-text text-${labelColor(infoErrors.targets)}`}>Target</span>
            <InfoBox className="flex gap-2 p-3 maxMd:justify-around">
              <Switch
                id="webToggle"
                checked={info.targetOptions.WEB}
                onToggle={(checked) =>
                  setInfo((state) => ({
                    ...state,
                    targetOptions: { ...state.targetOptions, WEB: checked },
                  }))
                }
                label="web"
              />
              <Switch
                id="androidToggle"
                checked={info.targetOptions.APP_ANDROID}
                onToggle={(checked) =>
                  setInfo((state) => ({
                    ...state,
                    targetOptions: { ...state.targetOptions, APP_ANDROID: checked },
                  }))
                }
                label="android"
              />
              <Switch
                id="iosToggle"
                checked={info.targetOptions.APP_IOS}
                onToggle={(checked) =>
                  setInfo((state) => ({
                    ...state,
                    targetOptions: { ...state.targetOptions, APP_IOS: checked },
                  }))
                }
                label="ios"
              />
            </InfoBox>
            {!!infoErrors.targets && (
              <span className="text-warningRed-primary">{infoErrors.targets}</span>
            )}
          </div>
          <div className="flex flex-col gap-2 grow w-full maxXl:w-[50%] maxMd:w-full">
            <span className="label-text text-seriousBlack-primary">Contact</span>
            <InfoBox className="p-3">
              <Switch
                id="contactConfirmationToggle"
                checked={info.contactConfirmation}
                onToggle={(checked) =>
                  setInfo((state) => ({
                    ...state,
                    contactConfirmation: checked,
                  }))
                }
                label="Ask about contact"
              />
            </InfoBox>
          </div>
        </Row>
        <Row className="maxSm:flex-col">
          <div className="grow w-[50%] maxSm:w-full">
            <span className={`label-text text-${labelColor(infoErrors.type)}`}>Type</span>
            <InfoBox className="p-3 flex gap-4">
              <div className="form-control">
                <label className="label cursor-pointer flex gap-2">
                  <input
                    type="radio"
                    name="radio-type"
                    className="radio checked:bg-seriousBlack-primary checked:shadow-none h-5 w-5"
                    onChange={() => setInfo((state) => ({ ...state, type: 'MODAL' }))}
                    checked={info.type === 'MODAL'}
                  />
                  <span className="label-text text-seriousBlack-primary text-start">Modal</span>
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer flex gap-2">
                  <input
                    type="radio"
                    name="radio-type"
                    className="radio checked:bg-seriousBlack-primary checked:shadow-none h-5 w-5"
                    onChange={() => setInfo((state) => ({ ...state, type: 'FULL_SCREEN' }))}
                    checked={info.type === 'FULL_SCREEN'}
                  />
                  <span className="label-text text-seriousBlack-primary">Full Screen</span>
                </label>
              </div>
            </InfoBox>
            {!!infoErrors.type && (
              <span className="text-warningRed-primary">{infoErrors.type}</span>
            )}
          </div>
          <div className="grow w-[50%] maxSm:w-full">
            <span className={`label-text text-${labelColor(infoErrors.display_type)}`}>
              Display Type
            </span>
            <InfoBox className="p-3 flex gap-4">
              <div className="form-control">
                <label className="label cursor-pointer flex gap-2">
                  <input
                    type="radio"
                    name="radio-displayType"
                    className="radio checked:bg-seriousBlack-primary checked:shadow-none h-5 w-5"
                    onChange={() => setInfo((state) => ({ ...state, displayType: 'STEP_BY_STEP' }))}
                    checked={info.displayType === 'STEP_BY_STEP'}
                  />
                  <span className="label-text text-seriousBlack-primary text-start">
                    Step by Step
                  </span>
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer flex gap-2">
                  <input
                    type="radio"
                    name="radio-displayType"
                    className="radio checked:bg-seriousBlack-primary checked:shadow-none h-5 w-5"
                    onChange={() => setInfo((state) => ({ ...state, displayType: 'ALL_AT_ONCE' }))}
                    checked={info.displayType === 'ALL_AT_ONCE'}
                  />
                  <span className="label-text text-seriousBlack-primary">All at once</span>
                </label>
              </div>
            </InfoBox>
            {!!infoErrors.display_type && (
              <span className="text-warningRed-primary">{infoErrors.display_type}</span>
            )}
          </div>
        </Row>
        <Row className="maxSm:flex-col">
          <div className="grow w-[50%] maxSm:w-full">
            <span className="label-text text-seriousBlack-primary">Trigger</span>
            <InfoBox className="p-3 flex gap-4 flex-col">
              {info.trigger.type !== TriggerTypes.MANUAL && (
                <TextInput
                  placeholder="Enter the event name"
                  value={info.trigger.event_name}
                  onSearch={(event: ChangeEvent<HTMLInputElement>) =>
                    setInfo((state) => ({
                      ...state,
                      trigger: { ...state.trigger, event_name: event.target.value },
                    }))
                  }
                />
              )}

              <div className="flex gap-4">
                <div className="form-control">
                  <label className="label cursor-pointer flex gap-2">
                    <input
                      type="radio"
                      name="radio-triggerType"
                      className="radio checked:bg-seriousBlack-primary checked:shadow-none h-5 w-5"
                      onChange={() =>
                        setInfo((state) => ({
                          ...state,
                          trigger: { ...state.trigger, type: TriggerTypes.MANUAL },
                        }))
                      }
                      checked={info.trigger?.type === TriggerTypes.MANUAL}
                    />
                    <span className="label-text text-seriousBlack-primary text-start">Manual</span>
                  </label>
                </div>
                <div className="form-control">
                  <label className="label cursor-pointer flex gap-2">
                    <input
                      type="radio"
                      name="radio-triggerType"
                      className="radio checked:bg-seriousBlack-primary checked:shadow-none h-5 w-5"
                      onChange={() =>
                        setInfo((state) => ({
                          ...state,
                          trigger: {
                            ...state.trigger,
                            type: TriggerTypes.AUTO,
                            card: { title: '', subtitle: '' },
                          },
                        }))
                      }
                      checked={info.trigger?.type === TriggerTypes.AUTO}
                    />
                    <span className="label-text text-seriousBlack-primary">Auto</span>
                  </label>
                </div>
              </div>
            </InfoBox>
          </div>
          {info.trigger.type === TriggerTypes.MANUAL && (
            <div className="grow w-[50%] maxSm:w-full">
              <span className="label-text text-seriousBlack-primary">Card</span>
              <InfoBox className="p-3">
                <TextInput
                  placeholder="Enter the card title"
                  label="Title"
                  value={info.trigger.card?.title || ''}
                  onSearch={(event: ChangeEvent<HTMLInputElement>) =>
                    setInfo((state) => ({
                      ...state,
                      trigger: {
                        ...state.trigger,
                        card: { ...state.trigger.card, title: event.target.value },
                      },
                    }))
                  }
                  error={infoErrors['trigger.card.subtitle']}
                />
                <TextInput
                  placeholder="Enter the card subtitle"
                  label="SubTitle"
                  value={info.trigger.card?.subtitle || ''}
                  onSearch={(event: ChangeEvent<HTMLInputElement>) =>
                    setInfo((state) => ({
                      ...state,
                      trigger: {
                        ...state.trigger,
                        card: { ...state.trigger.card, subtitle: event.target.value },
                      },
                    }))
                  }
                  error={infoErrors['trigger.card.subtitle']}
                />
              </InfoBox>
            </div>
          )}
        </Row>
        <Row>
          <div className="w-full">
            <span className="label-text text-seriousBlack-primary">Period</span>
            <DateRange
              startDatePlaceholder="Select the start date"
              endDatePlaceholder="Select the end date"
              minDate={new Date()}
              onChange={(item) => setInfo((state) => ({ ...state, range: [item.selection] }))}
              ranges={info.range}
            />
          </div>
        </Row>
      </div>
    </Card>
  );
}

export default SurveyInfo;
