import React from 'react';
import { DateRange as ReactDateRange, DateRangeProps } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './customDatePickerWidth.css';

function DateRange(props: DateRangeProps) {
  return (
    <ReactDateRange
      className="customDatePickerWidth"
      rangeColors={['#2328Eb']}
      moveRangeOnFirstSelection={false}
      {...props}
    />
  );
}

export default DateRange;
