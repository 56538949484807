import React from 'react';

import { ContentBox } from 'components/atoms';
import { Audience } from 'components/organisms';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSurveyForm } from 'services/contexts/SurveyForm';

function CreateAudience() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [id] = searchParams.getAll('id');
  const { setAudiences, audiences } = useSurveyForm();

  function cancelCreation() {
    setAudiences((state) => state.filter((audience) => audience.id !== id));
  }

  function saveCreation() {
    const newAudience = audiences.filter((audience) => audience.id === id)[0];
    if (newAudience && !newAudience?.data.length) {
      cancelCreation();
    } else {
      navigate(-1);
    }
  }

  return (
    <ContentBox
      handleCancelOption={() => cancelCreation()}
      cancelOptionLabel="Delete"
      handleConfirmOption={() => saveCreation()}
      confirmOptionLabel="Save"
    >
      <Audience />
    </ContentBox>
  );
}

export default CreateAudience;
