import React, { ReactNode } from 'react';

interface RowProps {
  children: ReactNode;
  className?: string;
}

function Row({ children, className }: RowProps) {
  return (
    <div className={`flex flex-row justify-between w-full overflow-x-auto gap-4 ${className}`}>
      {children}
    </div>
  );
}

export default Row;
